<!-- prettier-ignore -->
<template>
  <aside id="drawer-navigation" class="fixed top-0 left-0 z-40 w-64 h-screen pt-14 transition-transform -translate-x-full bg-white border-r border-gray-200 md:translate-x-0 dark:bg-gray-800 dark:border-gray-700" aria-label="Sidenav">
    <div class="overflow-y-auto py-5 px-3 h-full bg-white dark:bg-gray-800">

      <!-- Search Field -->
      <form action="#" method="GET" class="md:hidden mb-2">
        <label for="sidebar-search" class="sr-only">Search</label>
        <div class="relative">
          <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
            <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"></path>
            </svg>
          </div>
          <input id="sidebar-search" type="text" name="search" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Search" />
        </div>
      </form>

      <!-- Navigation -->
      <ul class="space-y-2">
        <li>
          <NuxtLink to="/" class="flex items-center p-2 text-base font-medium text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
            <svg aria-hidden="true" class="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
              <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
            </svg>
            <span class="ml-3">Overview</span>
          </NuxtLink>
        </li>
        <li>
          <button type="button" class="flex items-center p-2 w-full text-base font-medium text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700" aria-controls="dropdown-pages" data-collapse-toggle="dropdown-pages">
            <svg aria-hidden="true" class="flex-shrink-0 w-6 h-6 text-gray-400 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z"></path><path d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"></path></svg>
            <span class="flex-1 ml-3 text-left whitespace-nowrap">Capture</span>
            <svg aria-hidden="true" class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
          </button>
          <ul id="dropdown-pages" class="hidden py-2 space-y-2">
              <li>
                <a href="#" class="flex items-center p-2 pl-11 w-full text-base font-medium text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700" @click.prevent="selectItem(DocumentType.note)">
                  Note
                  <Icon v-if="isloading && selectedItem === DocumentType.note" name="spinner" styles="w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600 ml-auto mr-1 flex-none" />
                  <span v-else class="ml-auto pr-1 flex-none text-xs font-semibold">⌘N</span>
                </a>
              </li>
              <li>
                <a href="#" class="flex items-center p-2 pl-11 w-full text-base font-medium text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700" @click.prevent="selectItem(DocumentType.mindmap)">
                  Mindmap
                  <Icon v-if="isloading && selectedItem === DocumentType.mindmap" name="spinner" styles="w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600 ml-auto mr-1 flex-none" />
                  <span v-else class="ml-auto pr-1 flex-none text-xs font-semibold">⌘P</span>
                </a>
              </li>
              <li>
                <a href="#" class="flex items-center p-2 pl-11 w-full text-base font-medium text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700" @click.prevent="selectItem(DocumentType.board)">
                  Canvas 
                  <Icon v-if="isloading && selectedItem === DocumentType.board" name="spinner" styles="w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600 ml-auto mr-1 flex-none" />
                  <span v-else class="ml-auto pr-1 flex-none text-xs font-semibold">⌘C</span>
                </a>
              </li>
          </ul>
        </li>
        <li>
          <NuxtLink to="/organize" class="flex items-center p-2 text-base font-medium text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
            <svg aria-hidden="true" class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clip-rule="evenodd"></path>
            </svg>
            <span class="flex-1 ml-3 whitespace-nowrap">Organize</span>
          </NuxtLink>
        </li>
        <li>
          <NuxtLink to="/retain" class="flex items-center p-2 text-base font-medium text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
            <svg aria-hidden="true" class="w-6 h-6 text-gray-400 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h4v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h4.002z"></path></svg>
            <span class="ml-3">Retain</span>
          </NuxtLink>
        </li>
      </ul>

      <!-- Divider -->
      <!-- <div class="pt-5 pl-2 mt-5 mb-4 text-sm text-gray-500 border-t border-gray-200 dark:text-gray-400 dark:border-gray-700"></div> -->

      <!-- Tags -->
      <div class="pt-5 pl-2 mt-6 mb-4 text-sm text-gray-500 border-t border-gray-200 dark:text-gray-400 dark:border-gray-700">
          <h3 class="mt-1">Document Tags</h3>
      </div>
      <ul class="pl-3 space-y-4">
        <li>
          <button v-for="tag in tags" :key="tag.name" class="mb-5 flex items-center text-base font-medium text-gray-900 rounded-lg transition duration-75 hover:underline dark:text-white group" @click="toggleTag(tag.name, '/organize')">
            <span :class="[tag.selected ? 'bg-blue-700' : 'bg-gray-400', 'w-4 h-4 rounded']"></span>
            <span class="ml-4 truncate">{{ tag.name }}</span>
          </button>
        </li>
      </ul>
    </div>
  </aside>
</template>

<script setup lang="ts">
import { initFlowbite } from "flowbite";
import { useTagStore } from "~/stores/useTagStore";
import { DocumentType, type IDocument } from "~/types/Document";

// initialize components based on data attribute selectors
onMounted(() => {
  initFlowbite();
});

//
// Tag Handling
//
const { setTags, tags, toggleTag } = useTagStore();
const { data } = await useFetch("/api/tags", {
  transform: (data) => data.tags,
});
setTags(data.value);

//
// Document creation
//
const router = useRouter();
const isloading = ref(false);
const selectedItem = ref<DocumentType | null>(null);

const selectItem = async (type: DocumentType) => {
  if (isloading.value) return;
  selectedItem.value = type;
  isloading.value = true;
  await createDocument(type);
  // isloading.value = false; --> Not necessary?
};

const pathForDocument = (type: DocumentType) => {
  switch (type) {
    case DocumentType.note:
      return "/note";
    case DocumentType.mindmap:
      return "/mindmap";
    case DocumentType.board:
      return "/board";
  }
};

const titleForDocument = (type: DocumentType) => {
  switch (type) {
    case DocumentType.note:
      return "Untitled Note";
    case DocumentType.mindmap:
      return "Untitled Mindmap";
    case DocumentType.board:
      return "Untitled Board";
  }
};

const contentForDocument = (type: DocumentType) => {
  switch (type) {
    case DocumentType.note:
      return "";
    case DocumentType.mindmap:
      return [{ name: `${titleForDocument(type)}`, children: [] }];
    case DocumentType.board:
      return "";
  }
};

const createDocument = async (type: DocumentType) => {
  const { data, error } = await useFetch("/api/documents", {
    method: "POST",
    body: {
      title: titleForDocument(type),
      content: contentForDocument(type),
      updatedAt: new Date(),
      createdAt: new Date(),
      type,
      trashed: false,
    },
    transform: (data: IDocument<typeof type> | null) => data,
  });
  if (error.value) {
    console.log("Error saving document: ", error.value);
  } else if (data.value) {
    const doc = data.value;
    await router.push({ path: `${pathForDocument(type)}-${doc._key}` });
  }
};
</script>
