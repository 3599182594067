<!-- prettier-ignore -->
<template>
  <div>
    <GlobalSearchCommand />
    <div>
      <OverviewNavBar />
      <OverviewSideBar />
      <main class="md:ml-64 h-auto pt-14">
        <slot />
      </main>
    </div>
  </div>
</template>
